const NavSearch = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M8.74999 15.8332C12.662 15.8332 15.8333 12.6619 15.8333 8.74984C15.8333 4.83782 12.662 1.6665 8.74999 1.6665C4.83797 1.6665 1.66666 4.83782 1.66666 8.74984C1.66666 12.6619 4.83797 15.8332 8.74999 15.8332Z"
        stroke="currentColor"
        strokeWidth="1.66667"
      />
      <path
        d="M14.25 14.25L17.605 17.605"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default NavSearch
